/* eslint-disable jsx-a11y/role-supports-aria-props */
import React from "react"
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { SolutionSlide, SolutionBanner } from "src/templates"

import Carousel from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "src/assets/styles/slick-cumplo-theme.css";

import leftButton from "src/assets/svg/buttons/slider-button-left.svg";
import rightButton from "src/assets/svg/buttons/slider-button-right.svg";

const SlickArrowLeft = ({ currentSlide, slideCount, ...props }) => (
 <img
  {...props}
  className={
   "slick-prev-soluciones slick-arrow-soluciones" +
           (currentSlide === 0 ? " slick-disabled-soluciones" : "")
  }
  aria-hidden="true"
  aria-disabled={currentSlide === 0 ? true : false}
  type="button"
  src={leftButton}
  alt="leftButton"
 />
);
const SlickArrowRight = ({ currentSlide, slideCount, ...props }) => {
 return (
  <img
   {...props}
   className={
    "slick-next-soluciones slick-arrow-soluciones" +
           (currentSlide === slideCount - 1 ? " slick-disabled-soluciones" : "")
   }
   aria-hidden="true"
   aria-disabled={currentSlide === slideCount - 1 ? true : false}
   type="button"
   src={rightButton}
   alt="rightButton"
  />
 )};

const Solutions = ({items}) => {
 const theme = useTheme();
 const lg = useMediaQuery(theme.breakpoints.down('lg'))

 const styles = {
  carouselContainer: {
  },
  solutionBannerContainer: {
   display: 'flex',
   flexWrap: 'wrap',
   justifyContent: 'space-between',
   padding: "0px !important",
  },
 }

 const settings = {
  dots: true,
  arrows: true,
  rows: 1,
  fade: true,
  dotsClass: 'slick-dots-solutions',
  prevArrow: <SlickArrowLeft />,
  nextArrow: <SlickArrowRight />,
 };

 return (



  <Box sx={styles.carouselContainer} id={'simulator'}>
   { !lg ? (
    <Carousel {...settings}>
     {items.map((solution, index) => (
      <SolutionSlide
       subtitle={solution.subtitle}
       title={solution.title}
       logo={solution.logo}
       description={solution.description}
       buttonText={solution.buttonText}
       buttonAction={solution.buttonAction}
       features={solution.features}
       form={solution.form}
       backgroundImage={solution.backgroundImage}
       key={index}
      />
     ))}
    </Carousel>
   ) : (
    <Container sx={styles.solutionBannerContainer}>
     {items.map((solution, index) => (
      <Box key={index}>
       <SolutionBanner
        subtitle={solution.subtitle}
        title={solution.title}
        logo={solution.logo}
        description={solution.description}
        buttonText={solution.buttonText}
        buttonAction={solution.buttonAction}
        features={solution.features}
        form={solution.form}
        backgroundImage={solution.backgroundImageMobile}
        solutionBackground={solution.solutionBackground}
        key={index}
       />
      </Box>
     ))}

    </Container>
   )}
  </Box>

 )}

export { Solutions }
